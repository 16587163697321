
// colours
$primaryValencia: #D63948;
$primaryBlue: #6746c3;
$primaryPurple: #311B92;
$primaryNavy: #000063;
$secondaryCarmine: #9E0021;
$secondaryBitterSweet: #FF6E73;
$secondaryMustard: #FFD95A;
$secondaryblue: #00ACC1;
$pickledBlueWood: #313951;
$grayAthens: #F6F7F9;
$grayGeyser: #DCE1E8;
$black: rgba(0,0,0,0.87);

// spacing
$spacer: 8px