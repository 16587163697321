@import './variables';
html,
body {
    margin: 0;
    min-height: 100%;
    height: 100%;
    background: $grayAthens;
}
#root {
    height: 100%;
}

img {
    max-width: 100%;
}

a {
    text-decoration: none;
    color: rgba(0, 0, 0, 0.87);

    &:hover {
        text-decoration: underline;
    }
    &:visited {
        color: rgba(0, 0, 0, 0.87);
    }
}

.footer {
    a {
        color: $pickledBlueWood;
    }
}

